import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import Button from '../components/ui/Button';
import RadioButtonGroup from '../components/ui/RadioButtonGroup';
import RadioButton from '../components/ui/RadioButton';
import SEOLayout from '../components/layouts/SEOLayout';
import StepperLayout from '../components/layouts/StepperLayout';
import { StepUrl } from '../utils/enums';
import BackButton from '../components/ui/BackButton';
import { navigateAndSetActiveStep } from '../utils/utils';
import {
  getHasLifeSupportEquipmant,
  setHasLifeSupportEquipmant,
} from '../utils/localStorage/localStorageFunctions';
import { YesNoType } from '../utils/localStorage/localStorageTypes';

const LifeSaving = () => {
  const [initialFormValues] = useState(() => {
    const hasLifeSupportEquipmant = getHasLifeSupportEquipmant();

    return { lifeSupportEquipmant: hasLifeSupportEquipmant };
  });

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_YOUR_HOUSEHOLD);
  };

  const handleNext = (values: typeof initialFormValues) => {
    const hasLifeSupportEquipmant = values?.lifeSupportEquipmant as YesNoType;
    setHasLifeSupportEquipmant(hasLifeSupportEquipmant);
    navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
  };

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={1}
        currentStep={5}
        pageUrl={StepUrl.URL_LIFE_SAVING}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Life Saving Equipment</h2>
                <div className="sub-heading">
                  Does anyone residing or intending to reside at your premises
                  require life support equipment?
                </div>
                <RadioButtonGroup className="my-2">
                  <RadioButton value="no" name="lifeSupportEquipmant" id="rad1">
                    No, life support equipment is not needed
                  </RadioButton>
                  <RadioButton
                    value="yes"
                    name="lifeSupportEquipmant"
                    id="rad2"
                  >
                    Yes, life support equipment needed
                  </RadioButton>
                </RadioButtonGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={
                    !(
                      props.values.lifeSupportEquipmant === 'no' ||
                      props.values.lifeSupportEquipmant === 'yes'
                    )
                  }
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default LifeSaving;
